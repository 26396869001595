html, body {
    margin: 0;
    padding: 0;
}

.fourLines {
    position: fixed;
    width: 100%;
    height: 100%;
}

.verticalLine {
    position: fixed;
    width: 1px;
    height: 100%;
    background-color: #dadada;
}

.verticalLine:nth-child(1) {
    left: 20%;
}

.verticalLine:nth-child(2) {
    left: 40%;
}

.verticalLine:nth-child(3) {
    left: 60%;
}

.verticalLine:nth-child(4) {
    left: 80%;
}

.page-container {
    padding-top: 200px;
    width: 100%;
    height: 100%;
    background-color: #efefef;
}