

/*.dialog-enter {
  left: -100%;
  transition: left 300ms linear;
}
.dialog-enter-active {
  left: 0;
}
.dialog-exit {
  left: 0;
  transition: left 300ms linear;
}
.dialog-exit-active {
  left: -100%;
}*/

.ReactModal__Overlay {
    opacity: 0;
    transform: translateX(-100px);
    transition: all 500ms ease-in-out;
}

.ReactModal__Overlay--after-open {
    opacity: 1;
    transform: translateX(0px);
}

.ReactModal__Overlay--before-close {
    opacity: 0;
    transform: translateX(-100px);
}