html, body {
  margin: 0;
  padding: 0;
}

.fourLines {
  width: 100%;
  height: 100%;
  position: fixed;
}

.verticalLine {
  width: 1px;
  height: 100%;
  background-color: #dadada;
  position: fixed;
}

.verticalLine:nth-child(1) {
  left: 20%;
}

.verticalLine:nth-child(2) {
  left: 40%;
}

.verticalLine:nth-child(3) {
  left: 60%;
}

.verticalLine:nth-child(4) {
  left: 80%;
}

.page-container {
  width: 100%;
  height: 100%;
  background-color: #efefef;
  padding-top: 200px;
}

form {
  width: 25%;
  flex-direction: column;
  margin: 0 auto;
  padding: 20px;
  display: flex;
  box-shadow: 0 0 20px #18181833, 0 5px 5px #0000003d;
}

label {
  color: #fff;
  margin-bottom: 4px;
  font-family: NeuzeitSLTStd-Book, sans-serif;
  text-decoration: underline;
}

input {
  height: 26px;
}

input, textarea {
  width: 100%;
  background: linear-gradient(315deg, #bebebe 0%, #d6d6d6 100%);
  border: none;
  border-bottom: 3px solid #7fffd4;
  margin-bottom: 40px;
  font-family: NeuzeitSLTStd-Book, sans-serif;
}

textarea {
  height: 200px;
  padding: 5px;
}

.submitBtn_AAuYva {
  color: #fff;
  width: 150px;
  cursor: pointer;
  background: linear-gradient(315deg, #bebebe 0%, #d6d6d6 100%);
  border: none;
  padding: 10px;
  font-family: NeuzeitSLTStd-Book, sans-serif;
  font-weight: lighter;
  transition: all .4s ease-in-out;
}

.submitBtn_AAuYva:hover {
  color: #000;
  background: linear-gradient(315deg, #d6d6d6 0%, #bebebe 100%);
}

.success_AAuYva > h2, .success_AAuYva > p {
  color: #fff;
  text-align: center;
  font-family: NeuzeitSLTStd-Book, sans-serif;
}

.Modal_rZlC_a {
  border: none;
  padding-top: 75px;
  padding-left: 0;
  position: absolute;
  inset: 0;
}

.Overlay_rZlC_a {
  z-index: 2;
  width: 100%;
  background: #0c0c0cf2;
  border: none;
  position: fixed;
  inset: 0;
}

.closeBtn_rZlC_a {
  cursor: pointer;
  width: 30px;
  height: 30px;
  filter: invert();
  background: none;
  border: none;
  transition: all .1s ease-in-out;
  position: absolute;
  top: 10px;
  left: 10px;
}

.closeBtn_rZlC_a:hover {
  transform: scale(1.2);
}

.Modal_rZlC_a > h2 {
  color: #fff;
  font-family: NeuzeitSLTStd-Book, sans-serif;
  font-weight: lighter;
}

@media screen and (max-width: 1100px) {
  form {
    width: 80%;
  }
}

.ReactModal__Overlay {
  opacity: 0;
  transition: all .5s ease-in-out;
  transform: translateX(-100px);
}

.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateX(0);
}

.ReactModal__Overlay--before-close {
  opacity: 0;
  transform: translateX(-100px);
}

.navbarBtn_tqjp8a {
  z-index: 9999;
  width: 40px;
  height: 50px;
  cursor: pointer;
  position: fixed;
  top: 30px;
  left: 34px;
}

.navbarLines_tqjp8a {
  height: 100%;
  width: 100%;
}

.navbarLines_tqjp8a:hover > .l0_tqjp8a, .navbarLines_tqjp8a:hover > .l1_tqjp8a, .navbarLines_tqjp8a:hover > .l2_tqjp8a, .navbarLines_tqjp8a:hover > .l3_tqjp8a {
  height: 55px;
  transform: translateY(-7px);
}

.line_tqjp8a {
  width: 3px;
  height: 40px;
  background-color: #2f2f2f;
  position: absolute;
}

.l0_tqjp8a {
  transition: all .1s ease-in;
}

.l1_tqjp8a {
  transition: all .2s ease-in;
  left: 12px;
}

.l2_tqjp8a {
  transition: all .3s ease-in;
  left: 24px;
}

.l3_tqjp8a {
  transition: all .4s ease-in;
  left: 36px;
}

@font-face {
  font-family: NeuzeitSLTStd-Bold;
  src: url("NeuzeitSLTStd-BookHeavy.747038d9.otf");
}

@font-face {
  font-family: NeuzeitSLTStd-Book;
  src: url("NeuzeitSLTStd-Book.a037fd38.otf");
}

@font-face {
  font-family: Francisco-Serial-Light;
  src: url("francisco-serial-light.6c244b58.ttf");
}

.heroContent_3tb8Eq {
  text-align: center;
  height: 100vh;
  width: 100%;
}

.heroText_3tb8Eq {
  width: 100%;
  height: 7rem;
  margin: auto;
  position: absolute;
  top: 30%;
  left: 50%;
  overflow: hidden;
  transform: translate(-50%, -50%);
}

.heroText_3tb8Eq ul {
  margin: 0 .675rem;
  padding: 0;
  animation: scrollUp_3tb8Eq 10s infinite;
}

.heroText_3tb8Eq ul li {
  color: #2d2d2d;
  height: 7rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: NeuzeitSLTStd-Bold, sans-serif;
  font-size: 5.5rem;
  list-style: none;
  display: flex;
}

.heroText_3tb8Eq:nth-child(1) {
  top: 40%;
}

h1, h2 {
  color: #2d2d2d;
  margin: 0;
  font-family: NeuzeitSLTStd-Bold, sans-serif;
}

h3 {
  color: #3a3a3a;
  margin-top: 25px;
  font-family: Francisco-Serial-Light, serif;
}

h4 {
  margin: 3px;
  font-family: NeuzeitSLTStd-Book, sans-serif;
  font-size: 14px;
}

.heroFooter_3tb8Eq {
  width: 100%;
  height: 100px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  display: flex;
  position: absolute;
  top: 65%;
}

@keyframes scrollUp_3tb8Eq {
  15%, 25% {
    transform: translateY(-25%);
  }

  40%, 50% {
    transform: translateY(-50%);
  }

  65%, 75% {
    transform: translateY(-75%);
  }
}

@keyframes translateArrow_3tb8Eq {
  from {
    transform: translateY(0);
  }

  to {
    transform: translateY(10px);
  }
}

.arrowDown_3tb8Eq {
  height: 30px;
  margin-top: 10px;
}

.goDown_3tb8Eq {
  cursor: pointer;
  width: 60px;
  transition: all .1s ease-in-out;
}

.goDown_3tb8Eq:hover {
  transform: scale(1.1);
}

.goDown_3tb8Eq:hover > .arrowDown_3tb8Eq {
  animation-name: translateArrow_3tb8Eq;
  animation-duration: .5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-direction: alternate;
}

@media screen and (max-width: 1250px) {
  .heroText_3tb8Eq {
    height: fit-content;
  }

  .heroText_3tb8Eq ul {
    animation: none;
  }

  .heroText_3tb8Eq ul li:nth-child(1), .heroText_3tb8Eq ul li:nth-child(3), .heroText_3tb8Eq ul li:nth-child(4) {
    display: none;
  }

  .heroText_3tb8Eq ul li {
    font-size: 4rem;
  }

  h1 {
    font-size: 2rem;
  }

  h3 {
    font-size: 1rem;
  }

  h4 {
    font-size: 12px;
  }

  .heroFooter_3tb8Eq {
    top: 80%;
  }
}

.FeaturedWork_PQfhCW {
  width: 100%;
  z-index: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 100px 0 0;
  padding: 0;
  display: flex;
}

.fwtitle_PQfhCW {
  text-align: center;
  color: #9a9a9a;
  z-index: 1;
  margin-bottom: 150px;
  transition: all .2s ease-in-out;
}

.fwtitle_PQfhCW:hover {
  color: #2d2d2d;
  transform: scale(1.025);
}

.FeaturedWorkItem_PQfhCW {
  width: 100%;
  text-align: center;
  justify-content: center;
  align-items: center;
  margin-bottom: 5vh;
}

.fwImg_PQfhCW {
  width: 40vw;
  height: 40vh;
  object-fit: cover;
  filter: grayscale() contrast(.3) brightness(1.75);
  transition: all .2s ease-in-out;
}

.fwImg_PQfhCW:hover {
  filter: grayscale(0);
  transform: scale(1.02);
}

@media screen and (max-width: 1100px) {
  .fwtitle_PQfhCW {
    margin-bottom: 50px;
    font-size: 2.5rem;
  }

  .fwImg_PQfhCW {
    width: 90%;
    height: auto;
  }
}

.footer_7akwSG {
  width: 100%;
  text-align: center;
  height: 80px;
  z-index: 1;
  background-color: #2d2d2d;
  flex-direction: row;
  justify-content: space-between;
  display: flex;
  position: absolute;
}

.signature_7akwSG {
  width: fit-content;
  flex-direction: row;
  align-items: center;
  margin-left: 50px;
  display: flex;
}

.signature_7akwSG > img {
  width: 150px;
  height: auto;
}

.centralFooter_7akwSG {
  width: fit-content;
  align-items: center;
  line-height: 30px;
  display: flex;
}

.centralFooter_7akwSG > h4 {
  color: #afafaf;
  letter-spacing: 1px;
  margin: 0;
}

.centralFooter_7akwSG > h4 > i {
  color: #5b5b5b;
}

.socialIcons_7akwSG {
  width: fit-content;
  flex-direction: row;
  align-items: center;
  margin-right: 50px;
  display: flex;
}

.socialIcons_7akwSG > img {
  filter: invert();
  width: 30px;
  height: 30px;
  cursor: pointer;
  margin-left: 5px;
  transition: all .1s ease-in-out;
}

.socialIcons_7akwSG > img:hover {
  transform: scale(1.1);
}

@media screen and (max-width: 1100px) {
  .centralFooter_7akwSG > h4 {
    font-size: 10px;
  }

  .socialIcons_7akwSG {
    margin-right: 2vw;
  }

  .signature_7akwSG {
    margin-left: 2vw;
  }

  .signature_7akwSG > img {
    width: 95px;
    height: auto;
  }
}

/*# sourceMappingURL=index.500ef6bd.css.map */
